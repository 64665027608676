<script setup>

const props = defineProps(
    {
      stories: Object,
      isFavourite: {
        type: Boolean,
        default: false
      }
    }
);

import listTile from './ListTile.vue'

const emit = defineEmits(['remove-bookmark'])

const handleRemoveBookmark = (uuid) => {
  emit('remove-bookmark', uuid);
};

</script>

<template>
  <div>
    <v-row>
      <v-col class="v-col-12">
        <div v-for="story in stories" :key="story._uid">
          <list-tile :story="story" :isFavourite="props.isFavourite" @remove-bookmark="handleRemoveBookmark"></list-tile>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
