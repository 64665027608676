<script setup>
const props = defineProps({ blok: Object, centralText: Boolean })

</script>

<template>
  <v-container :fluid="true" class="full-width-quote mt-md-6 mb-6">
    <v-row>
      <v-col :class="'d-none d-md-flex v-col-2 offset-0 d-flex justify-right ' + (props.centralText ? 'v-col-md-2 offset-md-1' : 'v-col-lg-2 offset-lg-3')"><div class="quote-start fill-height"></div></v-col>
      <v-col :class="'v-col-12 offset-0 text-center quote-text-container '  + (props.centralText ? 'v-col-md-6 offset-md-0' : 'v-col-md-8 v-col-lg-5 offset-md-0')">
        <div :class="'quote-text text-left ' + (props.centralText ? 'quote-text-spaced' : '')" v-html="blok.quote_text"></div>

      </v-col>
      <v-col :class="'d-none d-md-flex v-col-2 d-flex justify-end ' + (props.centralText ? 'v-col-md-2' : 'v-col-lg-2')"><div class="quote-end"></div></v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col :class="'v-col-12 offset-0 pt-0 pt-md-4 ' + (props.centralText ? 'v-col-md-4 offset-md-4' : 'v-col-md-5 offset-md-2 v-col-lg-4 offset-lg-5')"><p class="quote-credit">{{ blok.source }}</p></v-col>
    </v-row>

  </v-container>
</template>

<style scoped>

.full-width-quote {
  .quote-text-container {
    position: relative;
    z-index: 1;
    .quote-text {
      font-size: 1.9em;
      padding-top: 0;
      line-height: 1.2em;
      font-family: "DepartureMonoRegular", sans-serif !important;
      font-weight: normal;
      @media only screen and (max-width: 599px) {
        margin-top: 24px;
      }
    }
    .quote-text-spaced {
      margin: 0 24px;
    }
    @media only screen and (max-width: 599px) {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-image: url(@/assets/quote.svg);
        transform: rotate(180deg);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 80%;
      }
    }

  }
  @media only screen and (max-width: 599px) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.quote-start {
  background-image: url(@/assets/quote.svg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}
.quote-end {
  background-image: url(@/assets/quote.svg);
  transform: rotate(180deg);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

</style>
