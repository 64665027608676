<script setup>
import {onMounted, ref} from "vue";

const props = defineProps({ blok: Object, centralText: Boolean })

import {useHead} from '@unhead/vue'

const showPost = ref(false)

onMounted(() => {
  console.log("mounted")
  useHead({
    script: [
      // Load the script
      {
        src: 'https://www.instagram.com/embed.js',
        onload: (el) => {
          showPost.value = true
        }
      }
    ]
  })
})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col v-if="props.centralText" class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3 mt-0 mb-6 pb-0">
        <div class="d-flex justify-center" v-html="blok.embed_blockquote">

        </div>
      </v-col>
      <v-col v-else class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-6 offset-md-2 mt-0 mb-6 pb-0">
        <div class="d-flex justify-center" v-html="blok.embed_blockquote">

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
iframe {
  border: none;
}
</style>
