<script setup>
import {computed, ref} from "vue";

const props = defineProps({ blok: Object });

import Date from "@/components/Date.vue";
import CategoryIcon from "@/components/CategoryIcon.vue";
import Authors from "@/components/Authors.vue";

const inViewLeft = ref(false);
function onIntersectLeft(isIntersecting) {
  inViewLeft.value = isIntersecting;
}

const inViewRight = ref(false);
function onIntersectRight(isIntersecting) {
  inViewRight.value = isIntersecting;
}

const srcSetLandscape = computed(() => {

  let image = props.blok.stories[1].content.header[0].background_image
  let focus = props.blok.stories[1].content.header[0].background_image.focus
  let brightness = props.blok.stories[1].content.header[0].background_brightness
  let blur = props.blok.stories[1].content.header[0].background_blur

  return image.filename + '/m/592x333/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 592w,' +
      image.filename + '/m/960x540/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 960w,' +
      image.filename + '/m/640x360/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 640w' +
      image.filename + '/m/704x396/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 700w' +
      image.filename + '/m/800x450/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 800w'
})

const srcSetSquare = computed(() => {

  if (props.blok.stories[0].content.header[0].square_image) {
    let image = props.blok.stories[0].content.header[0].square_image

    return image.filename +
        '/m/600x600 600w,' +
        image.filename + '/m/960x960 960w,' +
        image.filename + '/m/640x640 640w' +
        image.filename + '/m/700x700 700w' +
        image.filename + '/m/800x800 800w'
  }
  else {
    let image = props.blok.stories[0].content.header[0].background_image
    let focus = props.blok.stories[0].content.header[0].background_image.focus
    let brightness = props.blok.stories[0].content.header[0].background_brightness
    let blur = props.blok.stories[0].content.header[0].background_blur

    return image.filename +
        '/m/600x600/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 600w,' +
        image.filename + '/m/640x640/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 640w' +
        image.filename + '/m/700x700/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 700w' +
        image.filename + '/m/800x800/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ') 800w'
  }
})

const srcSquare = computed(() => {

  if (props.blok.stories[0].content.header[0].square_image) {
    let image = props.blok.stories[0].content.header[0].square_image

    return image.filename +
        '/m/600x600'
  }
  else {
    let image = props.blok.stories[0].content.header[0].background_image
    let focus = props.blok.stories[0].content.header[0].background_image.focus
    let brightness = props.blok.stories[0].content.header[0].background_brightness
    let blur = props.blok.stories[0].content.header[0].background_blur

    return image.filename +
        '/m/600x600/filters:focal('+ focus.value +'):brightness(' + brightness.value + '):blur(' + blur.value + ')'
  }
})

const altSquare = computed(() => {

  if (props.blok.stories[0].content.header[0].square_image) {
    return props.blok.stories[0].content.header[0].square_image.alt
  }
  else {
    return props.blok.stories[0].content.header[0].background_image.alt
  }
})

</script>

<template>
  <section class="hp-section">
    <v-container
      fluid
      class="two-columns two-columns--variant d-md-flex pl-0 pr-0 pt-6 pb-6 mt-0 mb-0"
    >
      <v-row no-gutter>
        <v-col cols="12" md="5">
          <router-link :to="{ name: 'article', params: {slug: blok.stories[0].slug}}">
            <v-row class="h-100">
              <v-col cols="12" class="mb-6 mb-md-0">
                <div v-intersect="onIntersectLeft"
                     class="slide-in-left"
                     v-bind:class="{ 'slide-in-left in-view': inViewLeft }">
                  <div class="story-overlay text-white">
                    <div class="story-overlay--content pa-6">
                      <div class="d-flex flex-row">
                        <category-icon v-if="blok.stories[0].content.category" :category="blok.stories[0].content.category" colour="#ffffff" class="mr-4" />
                        <authors :reporters="blok.stories[0].content.reporters" />
                      </div>
                      <div class="text-white">
                        <h2
                          class="departure-mono-regular story-overlay--title mb-0 mb-md-6"
                        >
                          {{ blok.stories[0].name }}
                        </h2>
                        <p class="d-none d-md-block saans-mono-regular">
                          {{ blok.stories[0].content.header[0].standfirst }}
                        </p>
                      </div>
                    </div>
                    <v-img
                      aspect-ratio="1/1"
                      cover
                      :srcset="srcSetSquare"
                      sizes="(max-width: 600px) 600px, (min-width: 600px) 960px, (min-width: 960px) 640px, (min-width: 1280px) 700px, 800px"
                      :src="srcSquare"
                      lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                      :alt="altSquare"
                      class="w-100 mt-md-0"
                    ></v-img>
                  </div>
                </div>
              </v-col>
            </v-row>
          </router-link>
        </v-col>
        <v-col cols="12" md="2" class="position-relative">
          <div class="middle-border"></div>
        </v-col>
        <v-col cols="12" md="5">
          <router-link :to="{ name: 'article', params: {slug: blok.stories[1].slug}}">
          <div class="d-flex flex-column justify-space-between h-100">
            <div class="mt-0">
              <v-img
                v-intersect="onIntersectRight"
                class="slide-in-right"
                v-bind:class="{ 'slide-in-right in-view': inViewRight }"
                aspect-ratio="16/9"
                cover
                :srcset="srcSetLandscape"
                sizes="(max-width: 600px) 592px, (min-width: 600px) 960px, (min-width: 960px) 640px, (min-width: 1280px) 700px, 800px"
                :src="
                  blok.stories[1].content.header[0].background_image.filename +
                  '/m/1600x900/filters:focal(' +
                  blok.stories[1].content.header[0].background_image.focus +
                  ')'
                "
                lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                :alt="blok.stories[1].content.header[0].background_image.alt"
              ></v-img>
            </div>
            <div class="d-flex flex-column justify-start fill-height pt-4 pt-md-6 pt-md-12">
              <div class="d-flex flex-row meta">
                <category-icon v-if="blok.stories[1].content.category" :category="blok.stories[1].content.category" class="mr-4" />
                <authors :reporters="blok.stories[1].content.reporters" />
              </div>
              <h2
                class="heading-highlight smaller-title-section right-border text-2xl lg:text-3xl mb-0 mt-4 mt-md-6 mt-md-11"
              >
                <span>{{ blok.stories[1].name }}</span>
              </h2>
            </div>
          </div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style scoped lang="scss">
.meta {
  @media (width < 960px) {
    margin-left: 2px;
  }
}

.story-overlay {
  @media (width > 960px) {
    margin-top: 120px
  }
}
</style>
