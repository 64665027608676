<template>
  <v-container fluid class="pa-0">
    <v-row v-if="blok.text">
      <v-col v-if="centralText" :class="index === 0 ? 'v-col-12 offset-md-0 mt-0 pb-0' : 'v-col-12 offset-md-2 v-col-md-8 mt-0 pb-0'">
        <div :class="'body-text' + (index === 0 ? ' drop-cap' : '')" v-html="articleContent"></div>
      </v-col>
      <v-col v-else :class="index === 0 ? 'v-col-12 offset-0 mt-0 pb-0' : 'v-col-12 offset-0 v-col-md-7 offset-md-5 mt-0 pb-0'">
        <div :class="'body-text' + (index === 0 ? ' drop-cap' : '')" v-html="articleContent"></div>
      </v-col>
<!--      <v-col class="d-none d-lg-block v-col-md-3">-->
<!--        <Adsense-->
<!--            :data-ad-client="adsenseClientId"-->
<!--            :data-ad-slot="adsenseAdSlotId"-->
<!--        >-->
<!--        </Adsense>-->
<!--        <iframe src="https://cdn.thebannermen.com/wp-content/uploads/_banners/standard_Gatorade_Flow/Athletes/Athletes_blocksm_website/index.html" style="border: 0" />-->
<!--        <v-img :src="smallAd" />-->
<!--      </v-col>-->
    </v-row>
  </v-container>
</template>

<script setup>
import router from "@/router";

const props = defineProps({ blok: Object, index: Number, opEd: Boolean, person: Object, centralText: Boolean })

// import smallAd from '@/assets/placeholders/small-ad.png'

import { computed } from "vue";
import { renderRichText } from "@storyblok/vue";
console.log(props.blok.text.content[0].content)
const articleContent = computed(() => renderRichText(props.blok.text));
console.log(articleContent)

const adsenseClientId = () => {
  return import.meta.env.VITE_ADSENSE_CLIENT_ID
}

const adsenseAdSlotId = () => {
  return import.meta.env.VITE_ADSENSE_AD_SLOT_ID
}
</script>

<style scoped>
  h2 {
    margin-bottom: 0.1em;
  }
  h3 {
    margin-bottom: 0.1em;
  }
  p {
    margin-bottom: 1em;
    line-height: 1.2em;
  }
  .biog-container {

    @media only screen and (min-width: 1500px) {
      margin-left: 20px;
    }
  }
  .name-divider {
    margin-right: 10%;
    @media only screen and (min-width: 600px) {
      margin-right: 20%;
    }
  }
  .biog {
    font-family: "SaansMonoRegular", sans-serif !important;
    font-weight: normal;
    @media only screen and (max-width: 599px) {
      font-size: 1.1em;
      line-height: 1.4em;
    }
    @media only screen and (min-width: 600px) {
      font-size: 1.3em;
      line-height: 1.5em;
    }
  }
  .person {
    @media only screen and (min-width: 1280px) {
      margin-right: 40%;
    }
  }


</style>
