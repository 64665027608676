<script setup>

import {computed} from "vue";

const props = defineProps({ story: Object, isFavourite: Boolean })

import ArticleMeta from "@/components/ArticleMeta.vue";
import moment from "moment"

import Authors from "@/components/Authors.vue";

import router from "@/router";
import Bookmark from "@/components/Bookmark.vue";
import CategoryIcon from "@/components/CategoryIcon.vue";

const emit = defineEmits(['remove-bookmark'])
const handleRemoveBookmark = (uuid) => {
  emit('remove-bookmark', uuid);
};


const getCategory = (story) => {
  if (story.full_slug) {
    let path = story.full_slug.split("/");
    let category = path[1].split("-");
    return category[1];
  }
};

const getLink = computed(() => {
  if (props.story.full_slug.startsWith('podcasts/')) {
    return {
      name: 'podcast',
      params: { category: getCategory(props.story), slug: props.story.slug },
    }
  }
  else {
    return {
      name: 'article',
      params: { slug: props.story.slug }
    }
  }
})

</script>

<template>
  <div class="list-item d-flex w-100">
    <router-link
        :to="{ name: 'article', params: { slug: story.slug } }"
        class="list-item__image"
    >
      <v-img
          class="d-block d-md-none"
          aspect-ratio="3/4"
          cover
          width="200"
          :src="
          story.content.header[0].background_image.filename +
          '/m/600x800/filters:focal(' +
          story.content.header[0].background_image.focus +
          ')'
        "
      ></v-img>
      <v-img
          class="d-none d-md-flex"
          aspect-ratio="16/9"
          cover
          width="200"
          :src="
          story.content.header[0].background_image.filename +
          '/m/1600x900/filters:focal(' +
          story.content.header[0].background_image.focus +
          ')'
        "
      ></v-img>
    </router-link>

    <div class="pt-4 w-100">
      <v-row class="w-100">
        <v-col cols="12" sm="6" md="5" lg="5" xl="4">
          <div class="list-item__title ml-6 mb-4 mb-md-0">
            <!-- {{ story.content }} -->
            <router-link
                :to="{ name: 'article', params: { slug: story.slug } }"
            >
              {{ story.name }}
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="4" xl="4" class="d-none d-sm-block">
          <div class="list-item__standfirst ml-6">
            <router-link
                :to="{ name: 'article', params: { slug: story.slug } }"
            >
              {{ story.content.header[0].standfirst }}
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="3" xl="4">
          <div class="d-flex flex-row justify-start justify-md-space-between align-center ml-6">
            <div class="d-flex flex-row justify-start align-center">
              <category-icon v-if="story.content.category" :category="story.content.category" class="mt-0 mr-2" />

              <authors :reporters="story.content.reporters" class="ml-1" />

            </div>
            <bookmark :isFavourite="props.isFavourite" :uuid="story.uuid" @remove-bookmark="handleRemoveBookmark" class="ml-0" />

          </div>
        </v-col>
      </v-row>
    </div>
    <!-- <pre>{{ story.content }}</pre> -->
  </div>
<!--  <div>-->
<!--    <router-link-->
<!--        :to="getLink"-->
<!--    >-->
<!--      <v-img-->
<!--          aspect-ratio="16/9"-->
<!--          contain-->
<!--          class="rounded-lg mb-6"-->
<!--          :src="story.content.header[0].background_image.filename + '/m/700x394/filters:focal('+ story.content.header[0].background_image.focus +')'"-->
<!--      ></v-img>-->
<!--    </router-link>-->

<!--&lt;!&ndash;    <authors :reporters="story.content.reporters" />&ndash;&gt;-->

<!--    <div class="d-flex flex-row">-->
<!--      <ArticleMeta :date="story.first_published_at" :icons="[story.content.category, story.trending&&'trending', story.staff_pick&&'staff-pick']" :story="story" />-->
<!--      <bookmark :isFavourite="props.isFavourite" :uuid="story.uuid" @remove-bookmark="handleRemoveBookmark" />-->
<!--    </div>-->
<!--    <p class="mt-6 story-name">-->
<!--      <router-link-->
<!--          :to="getLink"-->
<!--      >{{ story.name }}</router-link>-->
<!--    </p>-->
<!--    <p class="mt-6">{{ story.content.header[0].standfirst }}</p>-->
<!--  </div>-->
</template>

<style scoped lang="scss">
  .story-name {
    font-family: "SaansMonoBold", sans-serif !important;
    font-weight: 600;
    a {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  @mixin rtl($property, $mb-value, $d-value) {
    $mb-context: calc(348 * 0.01) * 1px;
    $vw-context: calc(1300 * 0.01) * 1px;

    #{$property}: calc($mb-value / $mb-context) * 1vw;

    @media (width >= 600px) {
      #{$property}: calc($d-value / $vw-context) * 1vw;
    }

    @media (width >= 1400px) {
      #{$property}: $d-value;
    }
  }

  .v-container,
  .v-row,
  div[class*="v-col"] {
    padding: 0;
    margin: 0;
  }

  .list-item {
    border-top: 1px dotted black;
    padding: 0 0 48px 15px;
    position: relative;

    &__image {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;

      @media (width >= 600px) {
        flex: 0 0 186px;
        max-width: 186px;
      }
    }

    &__title {
      color: #000;
      font-family: "SaansMonoBold", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 128.947% */
      flex: 0 0 271px;

      //@include rtl(margin-left, 8px, 24px);
      @include rtl(padding-right, 8px, 72px);
    }

    &__standfirst {
      color: #000;
      font-family: "SaansMonoRegular", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 380;
      line-height: 24px; /* 138.462% */
      justify-self: start;
      margin-bottom: 16px;
      //@include rtl(margin-left, 24px, 0px);
    }

    &__meta {
      flex: 0 0 auto;
      @include rtl(margin-left, 10px, 0px);

      @media (width >= 960px) {
        justify-self: end;
      }
    }

    &:before {
      content: "";
      background-color: #eeeeee;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: -16px;
    }
  }
</style>
