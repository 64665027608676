import { defineStore } from "pinia";
import { inject } from "vue";
import axios from "axios";
import router from "@/router";

export const useAccountStore = defineStore("account", {
  state: () => ({
    authenticated: false,
    user: {},
    lastStory: null,
    price: null,
    promoCode: null,
    subscriberStatus: "anon",
    registrationStepOneCompleted: true,
    registrationStepTwoCompleted: false,
  }),
  persist: true,
  getters: {
    getAmount() {
      if (this.price === "monthly") {
        return import.meta.env.VITE_DIGITAL_MONTHLY;
      } else if (this.price === "yearly") {
        return import.meta.env.VITE_DIGITAL_YEARLY;
      } else if (this.price === "print") {
        return import.meta.env.VITE_DIGITAL_YEARLY_PRINT;
      }
      return 0;
    },
    getPaymentAmount() {
      let amount = this.getAmount;
      if (amount > 0) {
        return amount * 100;
      } else {
        return 0;
      }
    },
  },
  actions: {
    loggedIn(user) {
      this.authenticated = true;
      this.user = user;
      if (user.subscribed) {
        this.subscriberStatus = "paid";
      } else {
        this.subscriberStatus = "lgdin";
      }
    },
    logout() {
      this.authenticated = false;
      this.user = {};
    },
    register(name, email, password) {},
    updateUser(user) {
      this.user = user;
      if (user.subscribed) {
        this.subscriberStatus = "paid";
      }
    },
    updateUserVerified(date) {
      this.user.email_verified_at = date;
    },
    setLastStory(slug) {
      this.lastStory = slug;
    },
    setPrice(price) {
      this.price = price;
    },
    clearPrice() {
      this.price = null;
    },
    applyPromoCode(promoCode) {
      this.promoCode = promoCode;
    },
    clearPromoCode() {
      this.promoCode = null;
    },
    setStepOneCompleted() {
      this.registrationStepOneCompleted = true;
    },
    setStepTwoCompleted() {
      this.registrationStepTwoCompleted = true;
    },
  },
});
