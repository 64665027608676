<script setup>
  defineProps({ blok: Object });

  const goToMagazinePage = () => {
    window.location.href = "https://digitalfrontier.newsstand.co.uk/";
  };
</script>

<template>
  <section class="hp-section">
    <div class="magazine-promo-container">
      <v-row class="fill-height ma-0">
        <v-col class="v-col-12 v-col-md-4 pa-4 pa-md-6 pl-md-12 d-flex align-center">
          <h2 class="text-left">
            > A physical <br class="d-none" />artefact from <br class="d-none" />the digital <br class="d-none" />world_
          </h2>
        </v-col>
        <v-col class="v-col-12 v-col-md-4 pa-0">
          <div class="magazine-image-container">
            <v-img
              class="d-none d-md-block magazine-image"
              :src="blok.cover_image.filename + '/m/600x0/'"
              width="100%"
              cover
            ></v-img>
            <div class="d-block d-md-none pl-4 pr-4">
              <v-img
                class="magazine-image"
                :src="blok.cover_image.filename + '/m/400x0/'"
                width="100%"
                cover
              ></v-img>
            </div>
          </div>
        </v-col>
        <v-col class="v-col-12 v-col-md-3 offset-md-1 pa-4 pa-md-6 pr-md-12 d-flex flex-column justify-space-around align-center">
          <div>
            <p class="text-center text-md-left">
              The magazine exploring the intersection of humanity and technology, showcasing how innovation shapes our lives, cultures and
              futures.
            </p>
          </div>

          <div class="button-container">
            <v-btn
              color="white"
              rounded
              block
              variant="flat"
              class="mt-8 ma-md-0 pl-10 pr-10"
              @click="goToMagazinePage"
              >Buy Now</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .magazine-promo-container {
    position: relative;
    background-color: black;
    .magazine-image-container {
      @media only screen and (min-width: 960px) {
        margin-top: -30px;
      }

      //@media only screen and (min-width: 600px) {
      //  margin-top: 32px;
      //}
      //@media only screen and (min-width: 960px) {
      //  height: 361px;
      //  margin-top: -48px;
      //  margin-bottom: -48px;
      //}
    }
    .button-container {
      width: 100%;
      .v-btn {
        height: 48px !important;
        font-size: 18px;
      }
    }

    h2 {
      color: white;
      font-family: "DepartureMonoRegular", sans-serif;
      font-size: 40px;
      line-height: 50px;
      font-weight: 400;
    }
    p {
      font-family: "SaansMonoRegular", sans-serif;
      font-size: 1rem;
      line-height: 26px;
      color: white;
    }
    //@media only screen and (max-width: 599px) {
    //  height: auto;
    //  margin-left: -16px;
    //  margin-right: -16px;
    //}
  }
</style>
