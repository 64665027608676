<script setup>
  import { computed, ref } from "vue";

  const props = defineProps({
    category: {
      type: String,
    },
    colour: {
      type: String,
      default: "#000000",
    },
  });

  const fillColor = computed(() => props.colour);
</script>

<template>
  <router-link
    v-if="category === 'technology'"
    :to="{ name: 'category', params: { category: 'technology' } }"
    class="d-inline-flex text-white"
  >
    <!--    <v-icon size="small"-->
    <!--    ><img src="@/assets/icons/technology_icon.svg" alt="Technology Icon" width="24" height="25" /></v-icon-->
    <!--    >-->
    <svg
      width="24"
      height="25"
      class="custom-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      :style="{ '--svg-fill-color': fillColor }"
    >
      <path
        d="M11.99,.04C5.37,.04,0,5.42,0,12.06c0,6.27,4.8,11.42,10.93,11.97v-4.39l-3.67-3.42c-.48,.29-1.03,.46-1.63,.46-1.74,0-3.16-1.42-3.16-3.17s1.42-3.17,3.16-3.17,3.16,1.42,3.16,3.17c0,.53-.13,1.02-.36,1.46l2.51,2.33V8.75c-1.35-.37-2.35-1.61-2.35-3.09,0-1.76,1.43-3.21,3.2-3.21s3.2,1.43,3.2,3.21c0,1.47-1,2.71-2.35,3.09v11.39l4.33-3.75v-1.88c-1.33-.37-2.31-1.59-2.31-3.05,0-1.74,1.42-3.17,3.16-3.17s3.16,1.42,3.16,3.17c0,1.45-.98,2.67-2.31,3.05v2.66l-6.01,5.2v1.66c6.32-.34,11.35-5.57,11.35-11.99S18.62,.04,11.99,.04Z"
      />
      <path d="M12.98,5.67c0-.66-.54-1.2-1.2-1.2s-1.2,.54-1.2,1.2,.54,1.2,1.2,1.2,1.2-.54,1.2-1.2Z" />
      <path d="M18.98,11.48c0-.65-.53-1.18-1.18-1.18s-1.18,.53-1.18,1.18,.53,1.18,1.18,1.18,1.18-.53,1.18-1.18Z" />
      <path d="M4.45,13.52c0,.65,.53,1.18,1.18,1.18s1.18-.53,1.18-1.18-.53-1.18-1.18-1.18-1.18,.53-1.18,1.18Z" />
    </svg>
  </router-link>
  <router-link
    v-else-if="category === 'culture'"
    :to="{ name: 'category', params: { category: 'culture' } }"
    class="d-inline-flex"
  >
    <!--    <v-icon size="small"-->
    <!--    ><img src="@/assets/icons/culture_icon.svg" alt="Culture Icon" width="24" height="25" /></v-icon-->
    <!--    >-->
    <svg
      width="24"
      height="25"
      class="custom-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
    >
      <path d="M14.88,8.18c-.63-2.39-1.8-3.87-2.89-3.87s-2.26,1.48-2.89,3.87c.86,.26,1.82,.4,2.89,.4s2.03-.14,2.89-.4Z" />
      <path d="M8.81,14.51c.96-.28,2.03-.42,3.18-.42s2.22,.14,3.18,.42c.09-.55,.14-1.14,.17-1.76h-6.69c.03,.62,.08,1.21,.17,1.76h-.01Z" />
      <path d="M7.47,9.05c-.71-.34-1.35-.76-1.91-1.29-.7,1.04-1.14,2.26-1.26,3.57h2.93c.03-.8,.11-1.56,.24-2.29h0Z" />
      <path d="M16.75,11.33h2.93c-.12-1.31-.57-2.53-1.26-3.57-.55,.52-1.2,.94-1.91,1.29,.13,.72,.21,1.49,.24,2.29h0Z" />
      <path d="M17.52,6.65c-.7-.72-1.55-1.31-2.49-1.71,.48,.74,.87,1.66,1.17,2.7,.49-.27,.93-.6,1.32-.98h0Z" />
      <path d="M15.17,9.57c-.96,.28-2.03,.42-3.18,.42s-2.22-.14-3.18-.42c-.09,.55-.14,1.14-.17,1.76h6.69c-.03-.62-.08-1.21-.17-1.76h.01Z" />
      <path d="M8.95,4.94c-.94,.4-1.78,.99-2.49,1.71,.39,.38,.83,.72,1.32,.98,.29-1.04,.69-1.96,1.17-2.7h0Z" />
      <path d="M15.03,19.14c.94-.4,1.78-.99,2.49-1.71-.39-.38-.83-.72-1.32-.99-.29,1.04-.69,1.96-1.17,2.7Z" />
      <path d="M16.51,15.03c.71,.34,1.35,.77,1.91,1.29,.7-1.04,1.14-2.26,1.26-3.57h-2.93c-.03,.8-.11,1.56-.24,2.29h0Z" />
      <path
        d="M11.99,.04C5.36,.04-.01,5.41-.01,12.04s5.37,12,12,12,12-5.37,12-12S18.62,.04,11.99,.04Zm0,21.14c-5.04,0-9.14-4.1-9.14-9.14S6.95,2.9,11.99,2.9s9.14,4.1,9.14,9.14-4.1,9.14-9.14,9.14Z"
      />
      <path d="M6.46,17.43c.7,.72,1.55,1.31,2.49,1.71-.48-.74-.87-1.66-1.17-2.7-.49,.27-.93,.6-1.32,.98h0Z" />
      <path d="M7.23,12.75h-2.93c.12,1.31,.57,2.53,1.26,3.57,.55-.52,1.2-.94,1.91-1.29-.13-.72-.21-1.49-.24-2.29h0Z" />
      <path d="M9.1,15.9c.63,2.39,1.8,3.87,2.89,3.87s2.26-1.48,2.89-3.87c-.86-.26-1.82-.4-2.89-.4s-2.03,.14-2.89,.4Z" />
    </svg>
  </router-link>
  <router-link
    v-else-if="category === 'perspectives'"
    :to="{ name: 'category', params: { category: 'perspectives' } }"
    class="d-inline-flex"
  >
    <!--    <v-icon size="small"-->
    <!--    ><img src="@/assets/icons/perspectives_icon.svg" alt="Perspectives Icon" width="25" height="24" /></v-icon-->
    <!--    >-->
    <svg
      width="25"
      height="24"
      class="custom-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      :style="{ '--svg-fill-color': fillColor }"
    >
      <path
        d="M12.98,7.24c-2.58,0-4.67,2.1-4.67,4.67s2.1,4.67,4.67,4.67,4.67-2.1,4.67-4.67-2.1-4.67-4.67-4.67Zm0,7.39c-1.5,0-2.72-1.22-2.72-2.72,0-.37,.07-.72,.22-1.06,.09-.22,.34-.32,.56-.23,.22,.09,.32,.34,.23,.56-.1,.23-.15,.48-.15,.73,0,1.04,.84,1.87,1.87,1.87s1.87-.84,1.87-1.87-.84-1.87-1.87-1.87c-.23,0-.42-.19-.42-.42s.19-.42,.42-.42c1.5,0,2.72,1.22,2.72,2.72s-1.22,2.72-2.72,2.72Z"
      />
      <path
        d="M12.98,0C6.35,0,.98,5.37,.98,12s5.37,12,12,12,12-5.37,12-12S19.61,0,12.98,0Zm0,17.36c-3.78,0-7.21-2.37-9.22-4.1-.76-.66-.77-1.85,0-2.51,2.01-1.74,5.44-4.1,9.23-4.1s7.21,2.36,9.22,4.1c.76,.66,.76,1.85,0,2.51-2.01,1.74-5.44,4.1-9.22,4.1h0Z"
      />
    </svg>
  </router-link>
  <router-link
    v-else-if="category === 'company-building'"
    :to="{ name: 'category', params: { category: 'company-building' } }"
    class="d-inline-flex"
  >
    <!--    <v-icon size="small"-->
    <!--    ><img src="@/assets/icons/company_building_icon.svg" alt="Company Building Icon" width="24" height="24" /></v-icon-->
    <!--    >-->
    <svg
      width="24"
      height="24"
      class="custom-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :style="{ '--svg-fill-color': fillColor }"
    >
      <path d="M6.46,18.51h6.89v-3.83h-3.07l-3.82,3.83Z" />
      <path d="M9.32,10.66h-3.83v6.91l3.83-3.84v-3.07Z" />
      <path d="M13.35,13.33v-1.73l-1.73,1.73h1.73Z" />
      <path d="M10.67,10.66v1.71l1.71-1.71h-1.71Z" />
      <path d="M9.32,5.93l-3.39,3.38h3.39v-3.38Z" />
      <path d="M14.7,13.33h3.83V6.4l-3.83,3.85v3.08Z" />
      <path d="M10.67,5.48v3.83h3.06l3.81-3.83h-6.87Z" />
      <path
        d="M12,0C5.37,0,0,5.37,0,12s5.37,12,12,12,12-5.37,12-12S18.63,0,12,0Zm2.79,19.91l-.09-.09v.04H4.14V9.31h.02l-.07-.07,5.17-5.15,.06,.06v-.02h10.56V14.69h0l.05,.05-5.13,5.18h0Z"
      />
      <path d="M14.7,18.09l3.37-3.41h-3.37v3.41Z" />
    </svg>
  </router-link>
</template>

<style scoped lang="scss">
  .custom-svg {
    fill: var(--svg-fill-color); /* Use CSS variable for dynamic color */
  }
</style>
