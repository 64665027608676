//import './assets/main.css'
import { StoryblokVue, apiPlugin } from "@storyblok/vue";
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import App from "./App.vue";
import Article1Page from "./components/Article1Page.vue";
import AppHeader from "@/components/AppHeader.vue";
import LeadPhoto from "@/components/LeadPhoto.vue";
import LeadPhotoFull from "@/components/LeadPhotoFull.vue";
import TextOnly from "@/components/TextOnly.vue";
import IntroText from "@/components/IntroText.vue";
import DoubleImage from "@/components/DoubleImage.vue";
import SingleLandscapeImage from "@/components/SingleLandscapeImage.vue";
import SingleLandscapeVideo from "@/components/SingleLandscapeVideo.vue";
import FullWidthQuote from "@/components/FullWidthQuote.vue";
import LeadStory from "@/components/LeadStory.vue";
import RecentStories from "@/components/RecentStories.vue";
import FeatureArticle from "@/components/FeatureArticle.vue";
import FeaturedArticles from "@/components/FeaturedArticles.vue";
import LeadStories from "@/components/LeadStories.vue";
import StoryList from "@/components/StoryList.vue";
import List from "@/components/List.vue";
import FeaturedSecondary from "@/components/FeaturedSecondary.vue";
import Podcasts from "@/components/Podcasts.vue";
import Advert from "@/components/Advert.vue";
import AcastEmbed from "@/components/AcastEmbed.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import TikTokEmbed from "@/components/TikTokEmbed.vue";
import XEmbed from "@/components/XEmbed.vue";
import InstagramEmbed from "@/components/InstagramEmbed.vue";
import LinkedInEmbed from "@/components/LinkedInEmbed.vue";
import OneStory from "@/components/OneStory.vue";
import TwoStories from "@/components/TwoStories.vue";
import FeaturedStories from "@/components/FeaturedStories.vue";
import FeaturedStoriesSlider from "@/components/FeaturedStoriesSlider.vue";
import MagazinePromo from "@/components/MagazinePromo.vue";
import MobileSlider from "@/components/MobileSlider.vue";
import FeaturedPodcast from "@/components/FeaturedPodcast.vue";
import FeaturedPodcasts from "@/components/FeaturedPodcasts.vue";
import VueSocialSharing from "vue-social-sharing";
import VueSmoothScroll from "vue3-smooth-scroll";
import "./index.css";

import router from "./router";

const app = createApp(App);

import * as Sentry from "@sentry/vue";
import packageJson from "../package.json";

Sentry.init({
  app,
  dsn: "https://31132a50f5aa63169399c74dbd011c20@o4508053760573440.ingest.de.sentry.io/4508053960523856",
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
  logErrors: import.meta.env.PROD,
  debug: !import.meta.env.PROD,
  enabled: import.meta.env.PROD,
  environment: import.meta.env.PROD ? "production" : "development",
  release: "digital-frontier-frontend@" + packageJson.version,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.digitalfrontier\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(VueSocialSharing);
app.use(VueSmoothScroll);
app.use(router);

console.log("prod: " + import.meta.env.PROD);
console.log("mode: " + import.meta.env.MODE);

let apiModes = import.meta.env.VITE_API_MODES.split(",");
console.log(apiModes);
console.log("includes mode: " + apiModes.includes(import.meta.env.MODE));

app.use(StoryblokVue, {
  accessToken: import.meta.env.VITE_STORYBLOK_ACCESS_TOKEN,
  bridge: import.meta.env.MODE === "dev", // optimizes by excluding the bridge on production
  use: [apiPlugin],
});

app.component("basic-article", Article1Page);
app.component("header", AppHeader);
app.component("lead-photo", LeadPhoto);
app.component("lead-photo-full", LeadPhotoFull);
app.component("intro_text", IntroText);
app.component("text-only", TextOnly);
app.component("double-image", DoubleImage);
app.component("single-landscape-image", SingleLandscapeImage);
app.component("single-landscape-video", SingleLandscapeVideo);
app.component("full-width-quote", FullWidthQuote);
app.component("lead_story", LeadStory);
app.component("lead_stories", LeadStories);
app.component("recent_stories", RecentStories);
app.component("feature-article", FeatureArticle);
app.component("featured-articles", FeaturedArticles);
app.component("featured_secondary", FeaturedSecondary);
app.component("story-list", StoryList);
app.component("list", List);
app.component("podcasts", Podcasts);
app.component("advert", Advert);
app.component("acast-embed", AcastEmbed);
app.component("audio-player", AudioPlayer);
app.component("tiktok-embed", TikTokEmbed);
app.component("x-embed", XEmbed);
app.component("instagram-embed", InstagramEmbed);
app.component("linkedin-embed", LinkedInEmbed);
app.component("one-story", OneStory);
app.component("two-stories", TwoStories);
app.component("featured-stories", FeaturedStories);
app.component("mobile-slider", MobileSlider);
app.component("magazine-promo", MagazinePromo);
app.component("featured-stories-slider", FeaturedStoriesSlider);
app.component("featured-podcast", FeaturedPodcast);
app.component("featured-podcasts", FeaturedPodcasts);
app.component("two-story-feature", TwoStories);

// Vuetify
import "vuetify/styles";
import vuetify from "./plugins/vuetify.js";

app.use(vuetify);

import { createGtm } from "@gtm-support/vue-gtm";

app.use(
  createGtm({
    id: "GTM-KVHL3MW5",
    defer: false,
    compatibility: false,
    enabled: import.meta.env.PROD,
    debug: true,
    vueRouter: router,
    trackOnNextTick: false,
  })
);

import { createHead } from "@unhead/vue";
const head = createHead();
app.use(head);

import { VueReCaptcha } from "vue-recaptcha-v3";

app.use(VueReCaptcha, {
  siteKey: import.meta.env.VITE_RECAPTCHA_KEY,
  loaderOptions: { autoHideBadge: true },
});

app.mount("#app");
