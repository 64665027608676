<script setup>
  const props = defineProps({ name: String, standfirst: String });

  import { useShare } from "@vueuse/core";
  import { useRoute } from "vue-router";

  const { share, isSupported } = useShare();

  const route = useRoute();
  const url = "https://digitalfrontier.com" + route.fullPath;

  function startShare() {
    share({
      title: props.name,
      text: props.standfirst,
      url: url,
    });
  }
</script>

<template>
  <div class="ml-2 position-relative">
    <v-btn
      class="d-md-none"
      :disabled="!isSupported"
      @click="startShare"
      size="default"
      density="compact"
      color="transparent"
      variant="text"
      icon
    >
      <v-icon
        size="default"
        color="black"
        class="mr-3"
        >fas fa-share</v-icon
      >
    </v-btn>
    <v-speed-dial
      location="right center"
      transition="fade-transition"
      offset="32"
      open-on-hover
    >
      <template v-slot:activator="{ props: activatorProps }">
        <v-fab
          class="d-none d-md-inline-flex"
          v-bind="activatorProps"
          size="default"
          density="compact"
          color="transparent"
          variant="text"
          icon
        >
          <v-icon
            size="default"
            color="black"
            class="mr-3"
            >fas fa-share</v-icon
          >
        </v-fab>
      </template>

      <!-- <ShareNetwork
          network="facebook"
          class="v-btn v-btn--elevated v-btn--icon v-theme--light v-btn--density-default v-btn--size-default v-btn--variant-elevated fade-transition-move"
          tag="button"
          :url="url">
        <v-icon
            key="1"
            icon="fa-brands fa-facebook"></v-icon>
      </ShareNetwork> -->
      <ShareNetwork
        network="linkedin"
        class="v-btn v-btn--elevated v-btn--icon v-theme--light v-btn--density-default v-btn--size-default v-btn--variant-elevated fade-transition-move"
        tag="button"
        :url="url"
      >
        <v-icon
          key="2"
          size="small"
          icon="fa-brands fa-linkedin-in"
        ></v-icon>
      </ShareNetwork>
      <ShareNetwork
        network="twitter"
        class="v-btn v-btn--elevated v-btn--icon v-theme--light v-btn--density-default v-btn--size-default v-btn--variant-elevated fade-transition-move"
        tag="button"
        :url="url"
      >
        <v-icon
          key="3"
          size="small"
          icon="fa-brands fa-x-twitter"
        ></v-icon>
      </ShareNetwork>
    </v-speed-dial>
  </div>
</template>

<style lang="scss">
  .v-speed-dial__content {
    button {
      background-color: white !important;
    }
  }
</style>
