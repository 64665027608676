<script setup>
  import { computed } from "vue";
  import moment from "moment";

  const props = defineProps({
    date: {
      type: String,
      required: true,
    },
    align: {
      type: String,
      default: "left",
    },
    invert: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Array,
      default: [],
    },
    story: {
      type: Object,
      default: null,
    },
  });

  const getSeries = computed(() => {
    if (props.story.full_slug) {
      let path = props.story.full_slug.split("/");
      return path[1];
    }
  });

  const getLink = computed(() => {
    if (props.story.full_slug.startsWith("podcasts/")) {
      if (props.story.content.component === "podcast-series") {
        return {
          name: "podcast-series",
          params: { series: getSeries.value },
        };
      } else {
        return {
          name: "podcast",
          params: { series: getSeries.value, slug: props.story.slug },
        };
      }
    } else {
      return {
        name: "article",
        params: { slug: props.story.slug },
      };
    }
  });

  const dateToCheck = moment(props.date);
  const sevenDaysAgo = moment().subtract(7, "days");
</script>

<template>
  <div
    class="article-meta d-flex justify-start flex-wrap align-center"
    :class="{
      'align-self-end align-self-md-start justify-end': align === 'right',
      invert: invert,
    }"
  >
    <div
      class="article-meta__icons d-flex justify-start"
      v-if="props.icons"
    >
      <!--      <v-icon v-if="dateToCheck.isAfter(sevenDaysAgo)" class="mr-4"-->
      <!--      ><img src="@/assets/icons/new_icon.svg" alt="New Icon" width="43" height="26" /></v-icon-->
      <!--      >-->
      <router-link
        v-if="props.icons.includes('technology')"
        :to="{ name: 'category', params: { category: 'technology' } }"
        class="d-inline-flex"
      >
        <v-icon
          size="small"
          class="mr-4"
          ><img
            src="@/assets/icons/technology_icon.svg"
            alt="Technology Icon"
            width="24"
            height="25"
        /></v-icon>
      </router-link>
      <router-link
        v-if="props.icons.includes('culture')"
        :to="{ name: 'category', params: { category: 'culture' } }"
        class="d-inline-flex"
      >
        <v-icon
          size="small"
          class="mr-4"
          ><img
            src="@/assets/icons/culture_icon.svg"
            alt="Culture Icon"
            width="24"
            height="25"
        /></v-icon>
      </router-link>
      <router-link
        v-if="props.icons.includes('perspectives')"
        :to="{ name: 'category', params: { category: 'perspectives' } }"
        class="d-inline-flex"
      >
        <v-icon
          size="small"
          class="mr-4"
          ><img
            src="@/assets/icons/perspectives_icon.svg"
            alt="Perspectives Icon"
            width="25"
            height="24"
        /></v-icon>
      </router-link>
      <router-link
        v-if="props.icons.includes('company-building')"
        :to="{ name: 'category', params: { category: 'company-building' } }"
        class="d-inline-flex"
      >
        <v-icon
          size="small"
          class="mr-4"
          ><img
            src="@/assets/icons/company_building_icon.svg"
            alt="Company Building Icon"
            width="24"
            height="24"
        /></v-icon>
      </router-link>
      <v-icon
        v-if="props.icons.includes('trending')"
        size="small"
        class="mr-4"
        ><img
          src="@/assets/icons/trending_icon.svg"
          alt="Trending Icon"
          width="24"
          height="24"
      /></v-icon>
      <v-icon
        v-if="props.icons.includes('staff-pick')"
        size="small"
        class="mr-4"
        ><img
          src="@/assets/icons/staff_pick_icon.svg"
          alt="Staff Pick Icon"
          width="24"
          height="25"
      /></v-icon>
    </div>
    <div
      v-if="date"
      class="article-meta__date flex align-center text-black"
    >
      <span>
        {{ moment(date).format("MMM D YYYY") }}
      </span>
    </div>
    <!--    <div v-if="props.story" class="article-meta__arrow">-->
    <!--      <v-btn icon variant="text" color="transparent" size="small" :to="getLink">-->
    <!--        <v-icon size="small" color="black">-->
    <!--          <img src="@/assets/icons/arrow_icon.svg" alt="Arrow right" width="26" height="22" />-->
    <!--        </v-icon>-->
    <!--      </v-btn>-->
    <!--    </div>-->
  </div>
</template>

<style scoped lang="scss">
  .article-meta {
    .v-icon {
      width: auto;
    }
    &__date {
      border-left: 1px solid black;
      border-right: 1px solid black;
      padding-left: 8px;
      padding-right: 8px;
      line-height: 21px;
      height: 23px;

      span {
        font-family: "SaansMonoRegular", sans-serif;
        font-size: 0.81rem;
        line-height: 0;
        margin-bottom: -2px;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
      }
    }
    &__arrow {
      margin-left: 4px;
    }

    &.invert {
      filter: invert(1);
    }
  }
</style>
