<script setup>

import {useAccountStore} from "@/stores/account";

const props = defineProps({
  isFavourite: Boolean,
  uuid: String
});

import axios from "axios";
import {onMounted, ref} from "vue";
const accountStore = useAccountStore();

const userId = accountStore.user.id;
const localIsFavourite = ref();

let apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_HOST,
  withCredentials: true,
  withXSRFToken: true,
});

const submitFavourite = async () => {
  return apiClient.get("/sanctum/csrf-cookie").then(() => {
    return apiClient
        .post("/api/users/me-favourites", {
          story_uuid: props.uuid,
        })
        .then((response) => {
          // registration successful
          if (response.status === 200) {
            localIsFavourite.value = true;
          } else {
            return Promise.reject("not authorised");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  });
};

const emit = defineEmits(['remove-bookmark'])

import { useRoute } from 'vue-router';
const route = useRoute();

const removeFavourite = async () => {

  return apiClient.get("/sanctum/csrf-cookie").then(() => {
    return apiClient
        .delete("/api/users/me-favourites", { data: { story_uuid: props.uuid } })
        .then((response) => {

          if (response.status === 200) {
            if (route.name === 'bookmarks') {
              emit('remove-bookmark', props.uuid);
            }
            else {
              localIsFavourite.value = false;
            }
          } else {
            // return Promise.reject("not authorised");
          }
        })
        .catch((error) => {
          console.error(error); // Log the error
          return Promise.reject(error); // Reject the promise to propagate the error
        });
  });
};

onMounted(() => {
  localIsFavourite.value = props.isFavourite;
});
</script>

<template>
  <div v-if="userId" class="d-inline-block">
    <v-btn
        v-if="!localIsFavourite"
        icon
        size="default"
        density="compact"
        color="transparent"
        variant="text"
        @click="submitFavourite"
    >
      <v-icon size="small" color="black">fa-regular fa-bookmark</v-icon>

    </v-btn>
    <v-btn
        v-else
        icon
        size="default"
        density="compact"
        color="transparent"
        variant="text"
        @click="removeFavourite"

    >
      <v-icon v-if="route.name === 'bookmarks'" size="default" color="black">fa-solid fa-xmark</v-icon>
      <v-icon v-else size="small" color="black">fa-solid fa-bookmark</v-icon>
    </v-btn>
  </div>
</template>

<style scoped>

</style>
