<script setup>
import ArticleMeta from "@/components/ArticleMeta.vue";
import {computed} from "vue";

const props = defineProps({ blok: Object })

const srcSetPortrait = (podcast) => {

  let image = podcast.content.image
  let focus = podcast.content.image.focus

  return image.filename + '/m/600x800/filters:focal('+ focus.value +') 600w,' +
      image.filename + '/m/960x1280/filters:focal('+ focus.value +') 960w,' +
      image.filename + '/m/426x568/filters:focal('+ focus.value +') 426w' +
      image.filename + '/m/465x620/filters:focal('+ focus.value +') 465w' +
      image.filename + '/m/531x708/filters:focal('+ focus.value +') 531w'
}

const goApple = (id) => {
  window.open("https://podcasts.apple.com/gb/podcast/" + id, "_blank");
};

const goSpotify = (id) => {
  window.open("https://open.spotify.com/episode/" + id, "_blank");
};

</script>

<template>
  <v-row>
    <v-col v-if="blok.podcasts.length === 2" class="d-none d-md-block v-col-md-2">

    </v-col>
    <v-col
        v-if="blok.podcasts.length > 0"
        class="v-col-12 v-col-md-4"
        v-for="podcast in blok.podcasts"
        :key="podcast._uid"
    >
      <div class="story-overlay">
        <div class="story-overlay--content pa-6">
          <ArticleMeta
              :date="podcast.first_published_at ?? podcast.created_at"
              :icons="['podcast']"
              :story="podcast"
              invert
          />
          <div class="text-white">
            <h2
                class="cat-title-subheading"
            >
              {{ podcast.name }}
            </h2>
            <p class="story-overlay--standfirst">

            </p>
          </div>
        </div>
        <v-img
            v-if="podcast.content.image"
            aspect-ratio="3/4"
            cover
            :srcset="srcSetPortrait(podcast)"
            sizes="(max-width: 600px) 600px, (min-width: 600px) 960px, (min-width: 960px) 426px, (min-width: 1280px) 465px, 531px"
            :src="
                      podcast.content.image.filename +
                      '/m/600x800/filters:focal(' +
                      podcast.content.image.focus +
                      ')'
                    "
            ref="targetTwo"
        ></v-img>
      </div>
    </v-col>
  </v-row>
  <v-row class="featured-intro ml-0 mr-0">
    <v-col class="v-col-12 v-col-md-8 offset-md-2 pa-0 d-flex flex-column align-center">
      <p>Nec sed quis tristique etiam. Iaculis tempor accumsan malesuada in fermentum at nulla cras neque. Eget pretium.</p>
      <div class="d-flex align-end mt-6">
        <h3 class="cat-title-subheading mr-2">Listen on</h3>
        <div
            class="d-flex align-center"
        >
          <v-btn
              icon
              variant="flat"
              density="comfortable"
              @click="goApple()"
          >
            <v-icon size="large">fa-brands fa-apple</v-icon>
          </v-btn>
          <v-btn
              icon
              variant="flat"
              density="comfortable"
              @click="goSpotify()"
          >
            <v-icon size="large">fa-brands fa-spotify</v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">

.featured-intro {
  margin-top: 72px;
  border-bottom: 1px dotted black;
  padding-bottom: 48px;
  @media only screen and (max-width: 599px) {
    margin-top: 48px;
  }
  p {
    font-family: "DepartureMonoRegular", sans-serif;
    font-size: 2.5rem;
    line-height: 3.15rem;
    text-align: center;
  }
}
.story-overlay {
  position: relative;
  border-radius: 10px; /* Add border-radius */
  overflow: hidden; /* Ensure contents respect the rounded corners */

  &--content {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .article-meta {
    filter: invert(1);
  }
}
</style>
