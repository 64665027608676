<script setup>
  import CategoryIcon from "@/components/CategoryIcon.vue";

  const props = defineProps({ blok: Object });

  import Date from "@/components/Date.vue";

  import { computed, ref } from "vue";
  import Authors from "@/components/Authors.vue";

  const inView = ref(false);
  function onIntersect(isIntersecting) {
    console.log(isIntersecting);
    inView.value = isIntersecting;
  }

  const srcSet = computed(() => {
    let image = props.blok.story.content.header[0].background_image;
    let focus = props.blok.story.content.header[0].background_image.focus;
    let brightness = props.blok.story.content.header[0].background_brightness;
    let blur = props.blok.story.content.header[0].background_blur;

    return (
      image.filename +
      "/m/640x360/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 640w" +
      image.filename +
      "/m/704x396/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 700w" +
      image.filename +
      "/m/800x450/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 800w"
    );
  });
</script>

<template>
  <section class="hp-section transform-container">
    <div class="bg-blockBackground mt-16 one-story-layout-2">
      <router-link :to="{ name: 'article', params: { slug: blok.story.slug } }">
        <v-row class="mb-0">
          <v-col
            class="d-flex flex-column justify-start pt-4 pb-7 pl-7 pr-7 pl-md-0 pr-md-0 pt-md-12 pt-lg-16 v-col-12 v-col-md-4 offset-md-1"
            order="2"
            order-md="1"
          >
            <div class="d-flex flex-row pt-md-3">
              <category-icon
                v-if="blok.story.content.category"
                :category="blok.story.content.category"
                class="mr-4"
              />
              <authors :reporters="blok.story.content.reporters" />
            </div>
            <h2 class="heading-highlight smaller-title-section right-border mt-4 mt-md-6 mt-md-11">
              <span>{{ blok.story.name }}</span>
            </h2>
          </v-col>
          <v-col
            class="pt-0 pb-0 v-col-12 v-col-md-7 offset-md-0"
            order="1"
            order-md="2"
          >
            <div
              v-intersect="onIntersect"
              class="slide-in-right with-icon-offset-right"
              v-bind:class="{ 'slide-in-right in-view': inView }"
            >
              <v-img
                class="d-block d-md-none"
                aspect-ratio="3/4"
                cover
                :src="
                  blok.story.content.header[0].background_image.filename +
                  '/m/600x800/filters:focal(' +
                  blok.story.content.header[0].background_image.focus +
                  ')'
                "
                lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                :alt="blok.story.content.header[0].background_image.alt"
              ></v-img>
              <v-img
                class="d-none d-md-block"
                aspect-ratio="16/9"
                cover
                :srcset="srcSet"
                sizes="(min-width: 960px) 640px, (min-width: 1280px) 700px, 800px"
                :src="
                  blok.story.content.header[0].background_image.filename +
                  '/m/640x360/filters:focal(' +
                  blok.story.content.header[0].background_image.focus +
                  ')'
                "
                lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                :alt="blok.story.content.header[0].background_image.alt"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </router-link>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .shift-up {
    margin-top: -50px;
  }
  .heading-highlight {
    @media only screen and (min-width: 600px) {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
    @media only screen and (min-width: 960px) {
      width: 80%;
      font-size: 2.2rem;
      line-height: 2.5rem;
    }
    @media only screen and (min-width: 1920px) {
      font-size: 50px;
      line-height: 58px;
    }

    &.right-border {
      //border-left: 2px solid #c4c4c4;
      text-align: left;
      padding-left: 0;

      @media (width >= 960px) {
        border-left: 2px solid #c4c4c4;
        border-right: none;
        text-align: left;
        padding-left: 20px;
      }
    }
  }

  .one-story-layout-2 {
    .v-img {
      @media (width >= 960px) {
        margin-top: -24px !important;
      }
      @media (width < 599px) {
        margin: -16px 16px 0 16px;
      }
    }
  }

  .transform-container {
    @media (width < 599px) {
      margin-left: -16px !important;
      margin-right: -16px !important;
    }
  }

  .with-icon-offset-right {
    position: relative;
    .v-img {
      @media (width >= 960px) {
        margin-bottom: 24px;
        margin-right: 24px;
      }
      @media (width <= 599px) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          background-image: url(@/assets/blocks-left-mobile.svg);
          background-size: 40px 40px;
          height: 40px;
          transform: rotate(-90deg);
          z-index: 2;
          aspect-ratio: 1/1;
        }
      }
    }
    &:after {
      @media only screen and (min-width: 600px) {
        content: "";
        display: block;
        position: absolute;
        right: -1px;
        bottom: -24px;
        z-index: 2;
        aspect-ratio: 1/1;
        background-image: url(@/assets/blocks-left.svg);
        background-size: 72px 72px;
        height: 72px;
      }
    }
  }

  .border-dotted-b {
    border-bottom: 1px dotted #8c8c8c;
  }

  .border-dotted-t {
    border-top: 1px dotted #8c8c8c;
  }
</style>
