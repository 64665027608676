<script setup>
import ArticleMeta from "@/components/ArticleMeta.vue";
import {computed} from "vue";

const props = defineProps({ blok: Object })

const srcSetLandscape = () => {

  let image = props.blok.podcast.content.image
  let focus = props.blok.podcast.content.image.focus

  return image.filename + '/m/960x540/filters:focal('+ focus.value +') 960w,' +
      image.filename + '/m/1280x720/filters:focal('+ focus.value +') 1280w,' +
      image.filename + '/m/1392x783/filters:focal('+ focus.value +') 1400w' +
      image.filename + '/m/1600x900/filters:focal('+ focus.value +') 1600w'
}

</script>

<template>
  <div class="featured-podcast story-overlay">
    <div class="story-overlay--content pa-6">
      <ArticleMeta
          :date="blok.podcast.first_published_at ?? blok.podcast.created_at"
          :icons="['podcast']"
          :story="blok.podcast"
          invert
      />
      <div class="text-white">
        <h2
            class="story-overlay--title"
        >
          {{ blok.podcast.name }}
        </h2>
        <p class="story-overlay--standfirst">

        </p>
      </div>
    </div>
    <v-img
        v-if="blok.podcast.content.image"
        aspect-ratio="3/4"
        cover
        :src="blok.podcast.content.image.filename + '/m/600x800/filters:focal('+ blok.podcast.content.image.focus +')'"
        :alt="blok.podcast.content.image.alt"
        class="d-block d-md-none mt-10 mt-md-0"
        ref="targetTwo"
    ></v-img>
    <v-img
        v-if="blok.podcast.content.image"
        aspect-ratio="16/9"
        cover
        :srcset="srcSetLandscape()"
        sizes="(min-width: 600px) 960px, (min-width: 960px) 1280px, (min-width: 1280px) 1400px, (min-width: 1920px) 1600px, 960px"
        :src="
                      blok.podcast.content.image.filename +
                      '/m/600x800/filters:focal(' +
                      blok.podcast.content.image.focus +
                      ')'
                    "
        class="d-none d-md-block mt-10 mt-md-0"
        ref="targetTwo"
    ></v-img>
  </div>
</template>

<style scoped lang="scss">
.featured-podcast {
  margin-top: 156px;
  margin-bottom: 48px;
  @media only screen and (max-width: 599px) {
    margin-top: 75px;
    margin-bottom: 48px;
  }
}
.story-overlay {
  position: relative;
  border-radius: 10px; /* Add border-radius */
  overflow: hidden; /* Ensure contents respect the rounded corners */

  &--content {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &--title {
    font-family: "SaansSemiMonoMedium", sans-serif;
    font-size: 1.2rem;
  }

  .article-meta {
    filter: invert(1);
  }
}
</style>
