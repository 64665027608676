<script setup>
import {computed, ref} from "vue";

const props = defineProps({ blok: Object });

import Date from "@/components/Date.vue";

const inView = ref(false);
function onIntersect(isIntersecting) {
  console.log(isIntersecting)
  inView.value = isIntersecting;
}

import cascade from "@/assets/blocks-cascade.svg";

import { useDisplay } from "vuetify";
import CategoryIcon from "@/components/CategoryIcon.vue";
import Authors from "@/components/Authors.vue";
const { smAndUp, smAndDown } = useDisplay();

const mobileSrc = computed(() => {
  if (props.blok.story.content.header[0].background_image_mobile.filename) {
    return props.blok.story.content.header[0].background_image_mobile.filename +
        '/m/600x800'
  }
  else {
    return props.blok.story.content.header[0].background_image.filename +
        '/m/1600x900/filters:focal(' +
        props.blok.story.content.header[0].background_image.focus +
        ')'
  }

})

const mobileAlt = computed(() => {
  if (props.blok.story.content.header[0].background_image_mobile.filename) {
    return props.blok.story.content.header[0].background_image_mobile.alt
  }
  else {
    return props.blok.story.content.header[0].background_image.alt
  }

})

const flexClasses = computed(() => {

  let header = props.blok.story.content.header[0]

  if (header.headline_position === 'centre_middle' || header.headline_position === 'centre_top' || header.headline_position === 'centre_bottom') {
    return 'd-flex justify-center'
  }

  return ''
})

const fullWidthClasses = computed(() => {

  let header = props.blok.story.content.header[0]

  if (header.headline_position === 'centre_middle') {
    return 'text-center align-center header-image'
  }
  else if (header.headline_position === 'centre_top') {
    return 'text-center align-start header-image'
  }
  else if (header.headline_position === 'centre_bottom') {
    return 'text-center align-end header-image'
  }
  if (header.headline_position === 'centre_left') {
    return 'text-left align-center header-image'
  }
  else if (header.headline_position === 'centre_right') {
    return 'text-right align-center header-image half-right'
  }
  else if (header.headline_position === 'top_left') {
    return 'text-left align-start header-image'
  }
  else if (header.headline_position === 'top_right') {
    return 'text-right half-right align-start header-image'
  }
  else if (header.headline_position === 'bottom_left') {
    return 'text-left align-end header-image'
  }
  else if (header.headline_position === 'bottom_right') {
    return 'text-right half-right align-end header-image'
  }
  else {
    return 'centre-bottom align-center header-image'
  }
})

</script>

<template>
  <section class="single-article pb-6 pb-md-0 hp-section" v-intersect="onIntersect">
    <v-row class="fill-height">
      <v-col class="v-col-12 fill-height">
        <router-link :to="{ name: 'article', params: {slug: blok.story.slug}}">
        <div
          class="d-flex fill-height"
        >

            <v-img
              class="d-block d-md-none pop-in-center with-icon-right"
              v-bind:class="{ 'pop-in-center in-view': inView }"
              aspect-ratio="3/4"
              cover
              :src="mobileSrc"
              lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
              :alt="mobileAlt"
            />

            <v-img
              :class="['d-none', 'd-md-flex', 'pop-in-center', 'with-icon-right', fullWidthClasses]"
              v-bind:class="{ 'pop-in-center in-view': inView }"
              aspect-ratio="16/9"
              cover
              :src="blok.story.content.header[0].background_image.filename + '/m/1600x900'"
              lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
              :alt="blok.story.content.header[0].background_image.alt"
            >
            <div v-if="blok.story.content.header[0].headline_over_image" :class="flexClasses">
              <div class="pa-12" :style="'color:' + blok.story.content.header[0].headline_colour.color + ';width:' + blok.story.content.header[0].headline_width + '%'">
<!--                <Date-->
<!--                    :date="blok.story.first_published_at"-->
<!--                    :color="blok.story.content.header[0].headline_colour.color"-->
<!--                    :class="{-->
<!--        'top-0 left-0 mt-6 ml-6': smAndDown,-->
<!--        'bottom-0 right-0 mb-6 mr-6': smAndUp,-->
<!--      }"-->
<!--                />-->
                <div :class="{
        'top-0 left-0 mt-6 ml-6': smAndDown,
        'bottom-0 right-0 mb-6 mr-6': smAndUp,
      }" class="d-flex flex-row">
                  <category-icon v-if="blok.story.content.category" :category="blok.story.content.category" :colour="blok.story.content.header[0].headline_colour.color" class="mr-4" />
                  <authors :reporters="blok.story.content.reporters" />
                </div>
                <h2 :class="'font-' + blok.story.content.header[0].headline_font_face" :style="'color:' + blok.story.content.header[0].headline_colour.color">
                  <span style="white-space: pre-line">{{ blok.story.name }}</span>
                </h2>
              </div>
            </div>
            </v-img>

        </div>
        </router-link>
      </v-col>
    </v-row>
<!--    <v-img-->
<!--      :src="cascade"-->
<!--      class="cascade position-absolute top-0 left-0 d-none d-sm-block"-->
<!--    />-->
    <div class="d-flex flex-row d-md-none mt-4 mt-md-6">
      <category-icon v-if="blok.story.content.category" :category="blok.story.content.category" class="mr-4" />
      <authors :reporters="blok.story.content.reporters" />
    </div>

    <h2 class="d-block d-md-none heading-highlight right-border mb-0 mt-4 mt-md-6">
      <span>{{ blok.story.name }}</span>
    </h2>
  </section>
</template>

<style scoped lang="scss">

/* Pop-in animation */
@keyframes popIn {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Apply the pop-in animation */
.pop-in-center.in-view {
  animation: popIn 0.6s ease-out;
}

.heading-highlight {

  //font-family: "SaansSemiMonoRegular", sans-serif !important;
  //font-size: 1.9rem;
  //font-weight: 200;
  @media (width >= 960px) {
    border-left: 2px solid #000;
  }
  &.right-border {
    text-align: left;
    @media (width >= 960px) {
      border-left: 2px solid #000;
      padding-left: 20px;
    }
  }
}

.single-article {

  position: relative;
  width: 100%; /* Full width of the container */

  .v-container,
  .v-row,
  div[class*="v-col"] {
    padding: 0;
  }

  .v-row {
    margin: 0;
  }

  @media (width >= 960px) {
    aspect-ratio: 16/9;
    //background: rgb(var(--v-theme-blockBackground)) url(@/assets/blocks-cascade.svg) no-repeat top left;
    //background-size: cover;
  }
}

.border-dotted-b {
  border-bottom: 1px dotted #8c8c8c;
}

.border-dotted-t {
  border-top: 1px dotted #8c8c8c;
}

img {
  aspect-ratio: 9/16;
  object-fit: cover;
  object-position: left bottom;

  @media (width >= 600px) {
    aspect-ratio: 16/9;
  }
}

h2 {
  font-weight: 300;
}

@media only screen and (max-width: 599px) {
  h2 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@media only screen and (min-width: 600px) {
  h2 {
    font-size: 1.875rem;
    line-height: 2.25rem
  }
}

@media only screen and (min-width: 960px) {
  h2 {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}

@media only screen and (min-width: 1280px) {
  h2 {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}

@media only screen and (min-width: 1920px) {
  h2 {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}

.with-icon-right {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    aspect-ratio: 1/1;
    @media only screen and (max-width: 599px) {
      background-image: url(@/assets/blocks-left-mobile.svg);
      transform: rotate(-90deg);
      background-size: 40px 40px;
      height: 40px;
    }
    @media only screen and (min-width: 600px) {
      background-image: url(@/assets/blocks-left.svg);
      background-size: 72px 72px;
      height: 72px;
    }
  }
}
</style>
