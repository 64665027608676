<script setup>
  const props = defineProps({
    blok: Object,
    reporters: Object,
    dark: Boolean,
    first_published_at: String,
    category: String,
    tags: Array,
    name: String,
    opEd: Boolean,
    isFavourite: Boolean,
    uuid: String,
    centralText: Boolean,
  });

  import { computed } from "vue";

  const background = computed(() => {
    if (props.dark) {
      return "background-dark";
    }

    return "background-light";
  });
</script>

<template>
  <v-container
    v-if="blok && blok.length > 0"
    class="header-container"
  >
    <component
      v-if="blok[0].full_width"
      is="lead-photo-full"
      :blok="blok[0]"
      :name="name"
      :reporters="reporters"
      :first_published_at="first_published_at"
      :category="category"
      :tags="tags"
      :opEd="opEd"
      :isFavourite="isFavourite"
      :uuid="uuid"
      :centralText="centralText"
    ></component>
    <component
      v-else
      is="lead-photo"
      :blok="blok[0]"
      :name="name"
      :reporters="reporters"
      :first_published_at="first_published_at"
      :category="category"
      :tags="tags"
      :opEd="opEd"
      :isFavourite="isFavourite"
      :uuid="uuid"
      :centralText="centralText"
    ></component>
  </v-container>
</template>

<style scoped>
  .header-container {
    padding-top: 16px;
    @media only screen and (max-width: 959px) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 8px;
    }
    @media only screen and (min-width: 960px) {
      max-width: 1280px !important;
      margin-bottom: 33px;
    }
    @media only screen and (min-width: 1280px) {
      max-width: 1400px !important;
    }
    @media only screen and (min-width: 1920px) {
      max-width: 1600px !important;
    }
  }
  .v-container--fluid {
    padding: 0 !important;
  }
  .background-dark {
    background-color: #222222;
    color: white;
  }
  .background-light {
    background-color: white;
    color: black;
  }
</style>
