<script setup>
import router from "@/router";

const props = defineProps({ blok: Object, reporters: Object, first_published_at: String, category: String, tags: Array, name: String, opEd: Boolean, isFavourite: Boolean, uuid: String, centralText: Boolean})

import { computed } from 'vue'
import Date from "@/components/Date.vue";
import Authors from "@/components/Authors.vue";
import Bookmark from "@/components/Bookmark.vue";

import { useShare } from "@vueuse/core";
import {useRoute} from "vue-router";

import Share from "@/components/Share.vue";

const search = (tag) => {
  router.push({name: 'search-tag', params: {tag: tag}})
}

const flexClasses = computed(() => {

  if (props.blok.headline_position === 'centre_middle' || props.blok.headline_position === 'centre_top' || props.blok.headline_position === 'centre_bottom') {
    return 'd-flex justify-center'
  }
  return ''
})

const fullWidthClasses = computed(() => {
  if (props.blok.headline_position === 'centre_middle') {
    return 'text-center align-center header-image'
  }
  else if (props.blok.headline_position === 'centre_top') {
    return 'text-center align-start header-image'
  }
  else if (props.blok.headline_position === 'centre_bottom') {
    return 'text-center align-end header-image'
  }
  if (props.blok.headline_position === 'centre_left') {
    return 'text-left align-center header-image'
  }
  else if (props.blok.headline_position === 'centre_right') {
    return 'text-right align-center header-image half-right'
  }
  else if (props.blok.headline_position === 'top_left') {
    return 'text-left align-start header-image'
  }
  else if (props.blok.headline_position === 'top_right') {
    return 'text-right half-right align-start header-image'
  }
  else if (props.blok.headline_position === 'bottom_left') {
    return 'text-left align-end header-image'
  }
  else if (props.blok.headline_position === 'bottom_right') {
    return 'text-right half-right align-end header-image'
  }
  else {
    return 'centre-bottom align-center header-image'
  }
})


</script>

<template>
  <div v-if="blok.background_video" style="padding:56.25% 0 0 0;position:relative" class="d-none d-sm-block hero-desktop">
    <iframe :src="'https://player.vimeo.com/video/' + blok.background_video + '?background=1&autoplay=1&loop=1'" responsive="true" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Background Video"></iframe>
  </div>
  <div v-if="blok.background_video_mobile" style="padding:177.78% 0 0 0;position:relative" class="d-block d-sm-none hero-desktop">
    <iframe :src="'https://player.vimeo.com/video/' + blok.background_video_mobile + '?background=1&autoplay=1&loop=1'" responsive="true" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Background Video"></iframe>
  </div>
<!--  <div v-else>-->
<!--    <v-img :src="blok.background_image.filename + '/m/filters:brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ')'" :class="fullWidthClasses">-->
<!--      <div v-if="blok.headline_over_image" class="pa-12 over-image-container" :style="blok.headline_colour ? 'color:' + blok.headline_colour.color : 'color:' + 'white'">-->
<!--        <h1 :class="'h1-over-image font-' + blok.headline_font_face + ' font-size-' + blok.headline_font_size" :style="'color:' + blok.headline_colour.color">-->
<!--          <span style="white-space: pre-line">{{ blok.headline }}</span>-->
<!--        </h1>-->
<!--      </div>-->
<!--    </v-img>-->
<!--  </div>-->

  <div v-else>
    <div class="d-block d-sm-none">
      <div class="aspect-ratio-box-3x4">
        <v-img
            v-if="blok.background_image_mobile && blok.background_image_mobile.filename"
            aspect-ratio="3/4"
            lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            :src="blok.background_image_mobile.filename + '/m/600x800'"
            :class="fullWidthClasses"
            :alt="blok.background_image_mobile.alt"
        >
        </v-img>
        <v-img
            v-else
            aspect-ratio="3/4"
            lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            :src="blok.background_image.filename + '/m/600x800/filters:focal('+ blok.background_image.focus +')'"
            :class="fullWidthClasses"
            :alt="blok.background_image.alt"
        >
        </v-img>
      </div>
      <div class="bg-primary header-title-block pt-12 pr-4 pb-6 pl-4">
        <div>
          <h1 class="h1-under-image">{{ name }}</h1>

          <Date
              :date="first_published_at"
              class="mb-6"
          />

          <p class="standfirst">{{ blok.standfirst }}</p>

          <authors class="mb-6" :reporters="reporters" />

          <div class="d-flex flex-row mb-md-6 bookmark-container">
            <bookmark :isFavourite="props.isFavourite" :uuid="props.uuid" />

            <share :name="name" :standfirst="blok.standfirst" />

          </div>


        </div>

      </div>
    </div>
    <div class="d-none d-sm-block aspect-ratio-box-16x9">
      <v-img
          aspect-ratio="16/9"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :srcset="blok.background_image.filename + '/m/1280x720/filters:focal('+ blok.background_image.focus +'):brightness(' + blok.background_image.value + '):blur(' + blok.background_image.value + ') 1280w,' + blok.background_image.filename + '/m/1392x783/filters:focal('+ blok.background_image.focus +'):brightness(' + blok.background_image.value + '):blur(' + blok.background_image.value + ') 1400w,' + blok.background_image.filename + '/m/1600x900/filters:focal('+ blok.background_image.focus +'):brightness(' + blok.background_image.value + '):blur(' + blok.background_image.value + ') 1600w'"
          sizes="(min-width: 960px) 1280px, (min-width: 1280px) 1400px, 1600px"
          :src="blok.background_image.filename + '/m/1280x720/filters:brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ')'"
          :class="fullWidthClasses"
          :alt="blok.background_image.alt"
      >
        <div :class="flexClasses">
          <div v-if="blok.headline_over_image" class="pa-12 over-image-container" :style="'color:' + blok.headline_colour.color + ';width:' + blok.headline_width + '%'">
            <h1 :class="'h1-over-image font-' + blok.headline_font_face + ' font-size-' + blok.headline_font_size" :style="'color:' + blok.headline_colour.color">
              <span style="white-space: pre-line">{{ name }}</span>
            </h1>
          </div>
        </div>
      </v-img>
      <div v-if="blok.headline_over_image && centralText" class="bg-primary header-title-block">
        <v-row v-if="category === 'perspectives'" class="fill-height mt-0 mb-0">
          <v-col class="v-col-12 v-col-md-4 pt-0 pb-0 fill-height">
            <div class="biog-img-container d-flex flex-row justify-start align-top pt-0 pb-0">
              <div>
                <v-img
                    aspect-ratio="3/4"
                    :src="reporters[0].content.profile_picture.filename + '/m/300x400'"
                    :alt="reporters[0].name"
                    contain
                    width="116"
                />
              </div>

              <p class="biog mt-0 mb-0 pl-6">{{ reporters[0].content.description }}</p>
            </div>

          </v-col>
          <v-col class="v-col-12 v-col-md-5 pt-0">
            <Date
                :date="first_published_at"
                class="mb-6"
            />
            <p class="standfirst">{{ blok.standfirst }}</p>
            <authors class="mb-6" :reporters="reporters" />
          </v-col>
          <v-col class="v-col-12 v-col-md-2 offset-md-1 pt-0">
            <div class="d-flex flex-row justify-center mt-0 bookmark-container">
              <bookmark :isFavourite="props.isFavourite" :uuid="props.uuid" />

              <share :name="name" :standfirst="blok.standfirst" />

            </div>
          </v-col>
        </v-row>
        <v-row v-else class="mt-0 mb-0">
          <v-col class="v-col-12 v-col-md-5 offset-md-0 pt-6 pl-3 pr-10">
            <div class="pl-12">
              <Date
                  :date="first_published_at"
                  class="mb-6"
              />
              <p class="standfirst">{{ blok.standfirst }}</p>
              <authors class="mb-6" :reporters="reporters" />
            </div>

          </v-col>
          <v-col class="v-col-12 v-col-md-2 offset-md-5">
            <div class="d-flex flex-row justify-center mt-3 bookmark-container">
              <bookmark :isFavourite="props.isFavourite" :uuid="props.uuid" />

              <share :name="name" :standfirst="blok.standfirst" />

            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="!blok.headline_over_image" class="bg-primary header-title-block">
        <v-row class="mt-0 mb-0">
          <v-col class="v-col-12 v-col-md-4 pt-0 pb-0 pl-3 pr-10">

            <h1 class="h1-under-image">{{ name }}</h1>

          </v-col>
          <v-col class="v-col-12 v-col-md-5 offset-md-1 pt-0 pb-0 pl-3 pr-10">
            <div class="date-container">
              <Date
                  :date="first_published_at"
                  class="mb-6"
              />
              <p class="standfirst">{{ blok.standfirst }}</p>
              <authors class="mb-0" :reporters="reporters" />
            </div>

          </v-col>
          <v-col class="v-col-12 v-col-md-2 pt-0">
            <div class="d-flex flex-row justify-md-center mt-3 pl-6 pl-md-0 bookmark-container">
              <bookmark :isFavourite="props.isFavourite" :uuid="props.uuid" />

              <share :name="name" :standfirst="blok.standfirst" />

            </div>
          </v-col>
        </v-row>
      </div>
    </div>


  </div>

</template>

<style scoped lang="scss">

.meta-container {
  border-left: solid 2px black;
  padding-left: 24px;
  p.standfirst {
    font-family: "SaansSemiMonoRegular", sans-serif !important;
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin-bottom: 24px;
  }
}
.share-container {
  margin-right: 24px;
}
.bookmark-container {
  margin-left: -6px;
}
.subtitle-xs {
  font-size: 1.4em;
}
.h1-over-image {
  line-height: 1em;
  font-weight: 400;
}
.h1-under-image {
  font-family: "SaansSemiMonoRegular", sans-serif;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.25rem;
  letter-spacing: 0.05rem;
  margin-bottom: 24px;
}

.standfirst {
  font-family: "SaansSemiMonoRegular", sans-serif !important;
  font-size: 1.375rem;
  line-height: 1.75rem;
}
.lead-caption {
  font-family: "SaansMonoRegular", sans-serif !important;
  text-transform: uppercase;
}

.half-right .over-image-container {
  float: right;
}

.header-title-block {
  position: relative;
  padding: 48px 0 48px 0;
  .biog-img-container {
    margin-left: 48px;
  }
  .biog {
    max-width: 200px;
  }
  .border-left {
    border-left: solid 1px black;
    padding-left: 16px;
  }
  &:after {

    @media only screen and (min-width: 600px) {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
      aspect-ratio: 1/1;
      background-image: url(@/assets/blocks-left.svg);
      background-size: 72px 72px;
      height: 72px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .h1-over-image {
    font-size: 2em;
  }
  .h1-under-image {
    font-size: 1.9em;
    line-height: 1.2em;
    padding-left: 0;
  }
  .standfirst {
    padding-bottom: 23px;
  }
  .lead-caption {
    margin-top: 10px
  }
}

@media only screen and (min-width: 600px) {
  .h1-over-image {
    font-size: 3em;
  }
  .date-container {
    padding-left: 24px;
  }
  .h1-under-image {
    font-size: 3.5em;
    line-height: 1.1em;
    padding-left: 24px;
  }
  .standfirst {
    padding-bottom: 23px;
  }
  .lead-caption {
    margin-top: 16px
  }
}

@media only screen and (min-width: 960px) {
  .standfirst {
    margin-bottom: 24px;
    margin-top: 0px;
  }
  .h1-over-image {
    font-size: 3em;
  }
  .date-container {
    padding-left: 0;
  }
  .h1-under-image {
    font-size: 2.3rem;
    line-height: 1.1em;
    padding-left: 48px;
  }
}

@media only screen and (min-width: 1280px) {
  .h1-over-image {
    font-size: 4em;
  }
}

@media only screen and (min-width: 1920px) {
  .h1-over-image {
    font-size: 7em;
  }
}

</style>
