<template>
<div>
  <v-container fluid class="bg-podcastsSub pt-11 pb-10 pt-md-16 pb-md-16">
    <v-row>
      <v-col class="d-block d-md-none v-col-sm-10 offset-sm-1 v-col-md-2 offset-md-1 pb-9">
        <h2 class="category">Podcasts</h2>
        <p class="view-more"><router-link :to="{name: 'podcast-index'}">View more</router-link></p>
      </v-col>
      <v-col class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-6 offset-md-1 pt-md-6 pb-md-6">
        <v-row>
          <v-col class="v-col-12 v-col-md-4">
            <v-card color="transparent" elevation="0">
              <router-link :to="{name: 'podcast-category', params: {category: 'dispatch'}}">
                <v-img src="https://a.storyblok.com/f/251220/600x600/8a1f352c6f/the-dispatch.jpg?cv=1707496464875" alt="The Dispatch"></v-img>
              </router-link>
            </v-card>

          </v-col>
          <v-col class="v-col-12 v-col-md-4">
            <v-card color="transparent" elevation="0">
              <router-link :to="{name: 'podcast-category', params: {category: 'dialogue'}}">
                <v-img src="https://a.storyblok.com/f/251220/600x600/f9b32675fa/the-dialogue-coming-soon.jpg?cv=1707765118169" alt="The Dialogue (coming soon)"></v-img>
              </router-link>
            </v-card>
          </v-col>

          <v-col class="v-col-12 v-col-md-4">
            <v-card color="transparent" elevation="0">
              <router-link :to="{name: 'podcast-category', params: {category: 'digest'}}">
                <v-img src="https://a.storyblok.com/f/251220/600x600/5acadd0c16/the-digest.jpg?cv=1707496465232" alt="The Digest"></v-img>
              </router-link>
            </v-card>
          </v-col>

        </v-row>
      </v-col>
      <v-col class="d-none d-md-block v-col-md-2 offset-md-1">
        <h2 class="podcasts mt-1">Podcasts</h2>
        <p class="view-more"><router-link :to="{name: 'podcast-index'}">View more</router-link></p>
      </v-col>
    </v-row>

  </v-container>
</div>
</template>

<script setup>

</script>

<style scoped lang="scss">

a {
  color: white;
  font-family: "DepartureMonoRegular", sans-serif !important;
}
</style>
